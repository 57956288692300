@font-face {
  font-family: 'TitlingGothicFB Wide Standard';
  src: url('/fonts/TitlingGothicFBWide-Standard.woff2') format('woff2'),
    url('/fonts/TitlingGothicFBWide-Standard.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RacingSansOne-Regular';
  src: url('/fonts/RacingSansOne-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Inter Regular */
@font-face {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Inter-Regular.woff2") format("woff2");
}

/* Inter Medium */
@font-face {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/Inter-Medium.woff2") format("woff2");
}

/* Inter SemiBold */
@font-face {
  font-family: "Inter-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/Inter-SemiBold.woff2") format("woff2");
}

/* Inter Bold */
@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Inter-Bold.woff2") format("woff2");
}




.custom-scrollbar {
  scrollbar-color: var(--scrollbar-color, var(--chakra-colors-gray-600, #777))
    var(--scrollbar-track, var(--chakra-colors-gray-500, #ccc));
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color, var(--chakra-colors-gray-600, #777));
  border-radius: 2px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(
    --scrollbar-color-hover,
    var(--scrollbar-color, var(--chakra-colors-gray-600, #777))
  );
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track, var(--chakra-colors-gray-500, #ccc));
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

.chakra-ui-light > *:first-child > *:first-child {
  background: linear-gradient(90deg, #DBD9E2 0%, #D9E4E9 100%);
}

#__next {
  background: linear-gradient(90deg, #DBD9E2 0%, #D9E4E9 100%);
}

.chakra-ui-light {
  background: linear-gradient(90deg, #DBD9E2 0%, #D9E4E9 100%);
}
#launcher iframe {
  padding-top: env(safe-area-inset-top); /* Adjust for safe area */
}

/* Disable iOS tap highlight */
button, 
a, 
[role="button"],
.chakra-button,
[class*="Button"] {
  -webkit-tap-highlight-color: transparent !important;
  tap-highlight-color: transparent !important;
  outline: none !important;
}

/* Remove focus outlines in iOS */
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Specifically target Chakra UI focus styles */
.chakra-button:focus, 
.chakra-button:focus-visible,
[class*="Button"]:focus,
[class*="Button"]:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border-color: transparent !important;
}